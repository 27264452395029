@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Rubik&display=swap');

/* ========================== font guide
* font-family: 'Barlow', sans-serif; // base font
* font-family: 'Chango', cursive; // effect font
* font-family: 'Source Sans Pro', sans-serif;  // clean font
*/

body {
  background: #f9a256;
  margin: 0;

  /* base font */
  font-family: 'gotham-book';
  font-size: 12px;
  color: #ffffff;
  overflow-x: hidden;
  box-sizing: border-box !important;
  transition: all 1.5s ease;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box !important;
}

button {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'gotham-bold';
  src: url('assets/font/Gotham-Bold.otf') format('openType');
}

@font-face {
  font-family: 'gotham-thin';
  src: url('assets/font/Gotham-Thin.otf') format('openType');
}

@font-face {
  font-family: 'gotham-book';
  src: url('assets/font/GothamBook.otf') format('openType');
}

@font-face {
  font-family: 'Wolfskin';
  src: url('assets/font/Wolfskin.otf') format('openType');
}

@font-face {
  font-family: 'Bergen Mono';
  src: url('assets/font/BergenMono-Regular.otf') format('openType');
}

@font-face {
  font-family: 'Bergen Mono';
  font-weight: bold;
  src: url('assets/font/BergenMono-Bold.otf') format('openType');
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
}

ul {
  padding: 0 0 0 20px;
  font-size: 13px;
  line-height: 20px;
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 20px;
  height: 30px;
  overflow: hidden;
  border: none;
  background: transparent;
  cursor: pointer;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
